.crypto {
     align-items: center;
}
.form {
     align-items: center;
     width:100%;
     height: 100%;
}
.projects {
     width: 100%;
     margin: auto;
     margin-top: 50px;
     text-align: center;
     justify-content:space-evenly;
     
}

.projectbox{
     border-radius: 25px;
     border: 5px solid black;
     padding: 20px;
     width: 75%;
     height: 200px;
     text-align: center;
     margin: 25px 25px;

}

.projectbox:hover{
     background-color: #b3cee5;
     
}

textarea {
     width: 50%;
     height: 100px;
}
.about {
     width: 80%;
     background-color: #f0f8ff;
     height: 520px;
     box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.5);
     margin: 8px;
     padding: 20px;
     display: flex;
     text-align: center;
}
.me > img {
     border-radius: 50%; 
}
.abouttext{
     width: 100%;
     margin-top: 25px;
     display: inline-block;
     text-align: justify;
     letter-spacing: 3px;
     margin-bottom: 10px;

}
.resumetitle{
     width: 75%;
     background-color: blue ;
     color: white;
     height: 50px;
     box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
     text-align: center;
}
.resume {
     width: 100%;
   }

.links{
     text-decoration: none;
     color: black;
}
.wrapper {
     display: grid;
     grid-template-columns: repeat(1fr, 2fr);
     gap: 20px;
     width: 95%;
     border-width: 1px;
     grid-auto-rows: minmax(auto, auto);
     align-content: center;
   }
   .one {
     grid-column: 1;
     grid-row: 1;
     width: 60%;
     border-style: solid;
     border-color: rgb(209, 204, 204);
     border-radius: 1rem;
     box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
     
   }
   .two {
     grid-column: 2;
     border-color: black;
     width: 60%;
     grid-row: 1;
     border-style: solid;
     border-color: rgb(209, 204, 204);
     border-radius: 1rem;
     box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
   }
   .three {
     grid-column: 2;
     grid-row: 1;
     width: 60%;
     border-style: solid;
     border-color: rgb(209, 204, 204);
     border-radius: 1rem;
     box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
   }
 
   .one:hover{
     background-color: #cfcfcf;
   }
   .two:hover{
     background-color: #cfcfcf;
   }
   .three:hover{
     background-color: #cfcfcf;
   }
  
   