* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.navbar {
  text-align: center;
  background: #005A9C;
  height: 80px;
  display: flex;
  
  justify-content: center;
  font-size: 1.2rem;
  position: sticky;
  top: 0;
  left: 0px;
  z-index: 999;
}

.navbar-container {
  display: flex;
  text-align: center;
  justify-content: space-between;
  height: 80px;
}

.container {

  width: 100%;
 display: flex;
 text-align: center;
  max-width: 1300px;
  margin: auto;
  padding-right: 50px;
  padding-left: 10px;
}

.navbar-logo {
  color: #fff;
  justify-self: start;
  cursor: pointer;
  text-decoration: none;
  font-size: 2rem;
  display: flex;
  align-items: center;
  margin-left: 50px;
}

.navbar-icon {
  margin-right: 0.5rem;
}

.nav-menu {
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  list-style: none;
  text-align: center;
  justify-content: flex-end;

}

.nav-item {
  height: 80px;
  border-bottom: 2px solid transparent;
  font-size: 28px;
  font-family: "Roboto", sans-serif;
}

.nav-item:hover {
  border-bottom: 2px solid #09bef0;
  background-color: #5576b8;
}

.nav-links {
  color: white;
  display: flex;
  align-items: center;
  text-decoration: none;
  font-weight: 600;
  padding: 0.5rem 1rem;
  height: 100%;
}

.activated {
  color: black;
}

.fa-bars {
  color: #fff;
}

.menu-icon {
  display: none;
}

@media screen and (max-width: 960px) {
  .NavbarItems {
    position: relative;
  }

  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    position: absolute;
    top: 80px;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
  }

  .nav-menu.active {
    background: #2196F3;
    left: 0;
    opacity: 1;
    transition: all 0.6s ease;
    z-index: 1;

  }

  .nav-links {
    text-align: center;
    font-size: large;
    padding: 2rem;
    width: 100%;
    display: table;
  }

  .nav-links:hover {
    color: #09bef0;
    transform: scale(1.2);
    transition: all 0.3s ease;
  }

  .nav-item:hover {
    border: none;
  }

  .nav-item {
    width: 100%;
  }

  .navbar-logo {
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(25%, 50%);
  }

  .menu-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
  }
}

