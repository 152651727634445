@import url('https://fonts.googleapis.com/css2?family=Outfit&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Outfit';
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: white;
  }

.WeatherApp {
  background-color: white linear-gradient rgba(0,0,0,0.75);
  background-position: bottom;
  background-size: height 100vh;
  
}

.searchbox {
  width: 100%;
  margin: 0 0 75px;

}
.button {
  background-color: #e7e7e7; 
  color: black; /* Green */
  border: none;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  font-size: 16px;
  
}
.searchbox .searchbar {
  display: block;
  width: 70%;
  padding: 15px;
  text-align: center;
  appearance: none;
  background:none;
  border: none;
  outline: none;
  margin-right: auto;
  margin-left: auto;
  background-color: #fff;
  border-radius: 0px 0px 16px 16px;
  margin-top: 0px;
  box-shadow: 0px 5px rgba(0,0,0,0.2);

  color: #313131;
  font-size: 20px;
}

.locationbox .location {
  color: black;
  font-size: 32px;
  font-weight: 900;
  font-family: 'Outfit' ;
  text-align: center;
}
.weatherbox {
  text-align: center;
}

.weatherbox .temp {
  position: relative;
  display: inline-block;
  margin: 30px auto;
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 16px;
  padding: 15px 25px;
  color: #fff;
  font-size: 102px;
  font-weight: 900;
  font-family: 'Outfit' ;
  text-shadow: 3px 6px rgba(50, 50, 70, 0.5);
  text-align: center;
  box-shadow: 3px 6px rgba(0,0,0,0.2);
}

.weatherbox .weather {
  color: #fff;
  font-size: 32px;
  font-weight: 700;
  text-shadow: 3px 3px rgba(50, 50, 70, 0.5);
}
.map-container {
  display: flex;
  width: 100px;
  height: 100px;
}

.quakemap-container {
  display: flex;
}
.additional {
  display:flex;
  width: 70%;
  justify-content: space-evenly;
  text-align: center;
  position: relative;
  margin: 30px auto;
  background-color: rgba(248, 243, 243, 0.95);
  border-radius: 16px;
  padding: 15px 25px;
  color: #fff;
  font-size: 102px;
  font-weight: 900;
  text-align: center;
  box-shadow: 3px 6px rgba(0,0,0,0.2);
}

.additional .feels{
  color: black;
  font-size: 13px;
  
  font-weight: 900;
}

main {
  min-height: 100vh;
  background-image: linear-gradient rgba(0,0,0,0.75);
}